.mainContainer {
    height: 100vh;
    width: 100vw;
    /* background-image: linear-gradient(180deg, rgba(2, 0, 36, 1) 0%, rgba(181, 175, 175, 0) 0%, rgb(247, 247, 247) 40%), url('../../src/assets/images/Apps_Background.svg'); */
    /* background-color: red; */
    background-size: cover;
    display: flex;
    flex-direction: column;
    .header {
        height: 10%;
        width: 100%;
        border-bottom: 2px solid #292d53;
        display: flex;
        justify-content: space-between;
        align-items: center;
        /* padding: 5px; */
        /* text-align: center; */
        font-family: Poppins, sans-serif;
      
    }

    .bodyContainer{
        height: 100%;
        width: 100%;
        display: flex;
        position: relative;
        /* justify-content: center;
        align-items: center; */
        /* padding: 0px 15px 0px 35px; */
       
        
    }
}




.imageContainer {
    height: 42px;
    /* width: 110px; */
    display: flex;
    justify-content: center;
    margin-right: 1px;
    padding: 4px;
}

.titleTxt {
    font-size: 24px;
    font-weight: 800;
    font-family: Poppins, sans-serif;
    color: #292d53;
}

.bi-report{
    /* position: relative; */
}

.overlay{
    position: absolute;
    background: #FFFFFF;
    right: 0;
    left: 0;
    height: 38px;
    bottom: 0;
}