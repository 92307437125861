.menu-container{
    display: flex;
    flex-direction: column;
    /* gap: 20px; */
    flex: 1;
    max-width: 150px;
    /* padding: 0 0 20px 0; */
    height: 100%;
    background-color: #002a7a;
    justify-content: space-between;
}

.menu-button{
    background-color: transparent;
    color: #FFFFFF;
    border: none;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    border-bottom: solid 1px rgba(255, 255, 255, .5);
    font-family: Poppins, sans-serif;
    /* border-radius: 5px ; */
    svg{
        height: 20px;
        width: 25px;
    }
    &:hover{
        background-color: #1ca9e1;
    }
}

.menu{
    display: flex;
    flex-direction: column;
}

.active{
    background-color: #1ca9e1;
}

.logout-button{
    background-color: transparent;
    color: #FFFFFF;
    border: none;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    border-top: solid 1px rgba(255, 255, 255, .5);
    font-family: Poppins, sans-serif;
    /* border-radius: 5px ; */
    svg{
        height: 20px;
        width: 25px;
    }
    &:hover{
        background-color: #1ca9e1;
    }
}