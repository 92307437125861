.divider:after,
.divider:before {
  content: "";
  flex: 1;
  height: 1px;
  background: #eee;
}
.h-custom {
  min-height: 74.5vh;
  height: calc(100% - 80px);
}
@media (max-width: 450px) {
  .h-custom {
    height: 100%;
  }
}
.botm {
  width: 100%;
  position: fixed;
  bottom: 0px;
}
.title-head {
  text-align: center;
  margin-bottom: 10%;
  margin-top: 10%;
  font-weight: 700;
  color: #007bff;
}
.nav-top {
  background: #326195 !important;
}
.nav-img {
  width: 100%;
  height: 55px;
}
.body_bg_login label {
  font-family: "Open Sans", sans-serif !important;
  color: #ffffff;
  display: block;
  margin-bottom: 8px;
}
.innr-body label {
  display: block;
  margin-bottom: 8px;
  color: #4d514c;
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Open Sans" !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.222px;
  text-transform: capitalize;
}
/* .innr-body-osha-log label {
  font-family: "Open Sans", sans-serif !important;
  color: black;
  display: block;
  margin-bottom: 0px;
} */
.d_title {
  color: black;
  font-size: 22px;
  font-weight: 600;
  justify-content: center;
  text-align: center;
  position: relative;
  margin: auto;
  padding: 5px;
}
.d_btn_row {
  width: 100%;
}
.d_top_form {
  margin: 0px 10px;
}
.botm_f {
  color: #000;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  border-top-width: 1px;
  border-top: 1px solid #e1d9d9;
}

.body_bg_login {
  /* background-image: url("../../assets/images/img1.jpg"); */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 100vh;
}
.login_form{
  padding-top: 50px;
}
.lg-head {
  color:#000;
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Open Sans";
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.222px;
  text-transform: capitalize;
}
.lg-head-sub {
  color: var(--light--new, #d4d9d2);
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Open Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.222px;
  text-transform: capitalize;
}
.lg-btn {
  display: flex;
  width: 100%;
  padding: 8.864px 23.636px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 5.909px;
  background: #10c4db;
  border-color: #10c4db;
  /* box-shadow: 0px 4.43182px 1.47727px -2.95455px rgba(0, 0, 0, 0.2),
    0px 2.95455px 2.95455px 0px rgba(0, 0, 0, 0.14),
    0px 1.47727px 7.38636px 0px rgba(0, 0, 0, 0.12); */
}
.log_img{
  /* padding-top: 20px; */
  display: flex;
  flex-direction: row;
  text-align: center;
  align-items: center;
}
.btm_seg{
  display: flex;
  flex-direction: row;
  gap:10px
  
}
.lg_bottom{
  color: var(--light--new, #8a8b89);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.btn-label {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
  color: #fff;
  font-weight: 400;
}
.lg-signup {
  color: #fff;
  font-size: 14px;
  text-align: left;
}
.lg-head-div {
  margin: 8% 0px;
}
.lg-com-log {
  width: 185px;
  height: 62.478px;
  flex-shrink: 0;
  float: right;
  margin-bottom: 2%;
}
.lg-label {
  color: var(--Base-Colour, #e1e1e1);
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Open Sans" !important;
  font-size: 17.727px;
  font-style: normal;
  font-weight: 400;
  line-height: 17.727px; /* 100% */
  letter-spacing: 0.222px;
}
.lg-input {
  background: transparent;
  font-size: 1rem;
}

.lg-form-control-lg {
  padding: 0.5rem 1rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.3rem;
  color: #fff;
}
.su-title {
  text-align: center;
  justify-content: center;
  position: relative;
}
.w-100 {
  width: 100%;
}
.lg-nave-title {
  color: var(--light--new, #d4d9d2);
  font-feature-settings: "clig" off, "liga" off;
  /* Heads--new */
  font-family: "Open Sans" !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.222px;
  text-transform: capitalize;
}
.lg-nave-title.active {
  color: #04a1e3;
}
.h-100 {
  height: inherit;
}

.innr-body {
  border-radius: var(--none, 0px);
  background: var(--Base-Colour, #e1e1e1);
}
.innr-body-osha-log {
  border-radius: var(--none, 0px);
  background: var(--Base-Colour, #e1e1e1);
}
.config-cnt {
  padding: 3% 3%;
}
.config-cnt-top {
  padding: 0% 3% 3% 3%;
}
.sub-title {
  color: var(--Grey, #4e4e4e);
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Open Sans" !important;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.222px;
  text-transform: capitalize;
}
.pad-2 {
  padding: 2%;
}

.sm-label {
  color: var(--Base-Colour, #e1e1e1);
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Open Sans" !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px; /* 100% */
  letter-spacing: 0.222px;
}
.item-center {
  align-items: center;
  text-align: center;
  position: relative;
}
.item-right {
  align-items: center;
  text-align: right;
  position: relative;
}
.config-btn {
  width: 100px;
}
.select-w-90 {
  width: 90px;
}
.title-margin-3 {
  margin: 2% 3%;
}

.config-cnt-log {
  padding: 0% 3% 5% 3%;
}
.btn-in {
  margin: 0 10px;
}

.btn-primary {
  color: #fff;
  background-color: #0f172a !important;
  border-color: #0f172a !important;
}

.btn-primary.disabled,
.btn-primary:disabled {
  color: #fff;
  background-color: #0f172a !important;
  border-color: #0f172a !important;
}
.display_none {
  display: none;
}

.wd-50 {
  width: 50%;
}
.wd-5 {
  width: 2%;
}
.wd-45 {
  width: 45%;
}
.lh-20 {
  line-height: 20px !important;
}
.overflow-auto {
  overflow: auto;
}
.white-space {
  white-space: nowrap;
}
.bg-c {
  background: #ccc;
}
.card-cs {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 0 solid rgba(0, 0, 0, 0.125);
  border-radius: 0.75rem;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.125), 0 1px 3px rgba(0, 0, 0, 0.2);
}
.br-top {
  border-top: 3px solid #2253a1;
}
.mb-6 {
  margin-bottom: 2.5rem;
}
.table-font {
  display: block;
  margin-bottom: 8px;
  color: #4d514c;
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Open Sans" !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.222px;
  text-transform: capitalize;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.text-aj {
  text-align: justify;
}

.table-bordered-cst th {
  font-weight: 800;
  border-bottom-width: 2px;
  color: #121212;
}

.mr-lt {
  margin-left: 14px;
}
.mr-rt-2 {
  margin-right: 2%;
}

/* Firefox */
* {
  scrollbar-width: auto;
  scrollbar-color: #555 #f5f5f5;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  height: 10px;
  width: 10px;
  background-color: #ccc;
}

*::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

*::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #555;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

table tr:last-child td:last-child {
  border-bottom-right-radius: 10px;
}

table tr:last-child td:first-child {
  border-bottom-left-radius: 10px;
}

table tr:first-child th:first-child,
table tr:first-child td:first-child {
  border-top-left-radius: 10px;
}

table tr:first-child th:last-child,
table tr:first-child td:last-child {
  border-top-right-radius: 10px;
}

table tr th:first-child,
table tr td:first-child {
  border-left: 2px solid #4d514c00;
}
table tr:first-child th,
table tr:first-child td {
  border-top: 2px solid #4d514c00;
}

.osha-text {
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Open Sans" !important;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.222px;
  text-transform: capitalize;
}
.osha-text-input {
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Open Sans" !important;
  font-size: 15px !important;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.222px;
}
.h-32 {
  height: 32px !important;
}
.mw-100 {
  max-width: 100%;
}

.osha-font-label {
  display: block;
  color: #4d514c;
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Open Sans" !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.222px;
}
.osha-font-text {
  display: block;
  color: #4d514c;
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Open Sans" !important;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.222px;
}
.ml-20 {
  margin: 0px 0px 0px 20px;
}
.mh-v81 {
  min-height: 72vh;
}
.sub-title-sub {
  font-size: 17px;
  font-weight: 600;
}

.table-font-list {
  display: block;
  margin-bottom: 8px;
  color: #4d514c;
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Open Sans" !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.222px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.login-form .form-control:focus {
  color: #cccdcf !important;
  background-color: transparent !important;
  border-color: #495057;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(118, 120, 122, 0.25);
}
.login-form input:-internal-autofill-selected {
  appearance: menulist-button;
  background-image: none !important;
  background-color: transparent !important;
  color: fieldtext !important;
}

.material-textfield {
  position: relative;
}

.login-form label {
  position: absolute;
  font-size: 1rem;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  background-color: #1f182800;
  color: var(--Base-Colour, #e1e1e1);
  padding: 0 0.3rem;
  margin: 0 0.5rem;
  transition: 0.1s ease-out;
  transform-origin: left top;
  pointer-events: none;
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Open Sans" !important;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.222px;
  text-transform: capitalize;
}
.login-form input {
  width: 100%;
  height: 42px;
  font-size: 1rem;
  outline: none;
  border: 1px solid #4950575c;
  border-radius: 5px;
  padding: 1rem 0.7rem;
  color: #cccdcf;
  transition: 0.1s ease-out;
}
.login-form input:focus {
  border-color: #c7c7c7;
}
.login-form input:focus + label {
  background-color: #101520;
  color: var(--Base-Colour, #e1e1e1);
  top: 0;
  transform: translateY(-50%) scale(0.9);
}
.login-form input:not(:placeholder-shown) + label {
  top: 0;
  transform: translateY(-50%) scale(0.9);
}

.login-form select {
  width: 100%;
  height: 42px !important;
  font-size: 15px !important;
  outline: none;
  border: 1px solid #4950575c;
  border-radius: 5px;
  padding: 0.5rem 0.7rem;
  color: #495057;
  transition: 0.1s ease-out;
}
.login-form select:focus {
  border-color: #c7c7c7;
}
.login-form select:focus + label {
  color: black;
  top: 0;
  transform: translateY(-50%) scale(0.9);
}
.login-form select:not(:placeholder-shown) + label {
  top: 0;
  transform: translateY(-50%) scale(0.9);
}

.login-banner{
 object-fit: cover;
}

.login{
  display: flex;
  height: 100vh;
  font-family: Poppins, sans-serif;
}

.login-banner{
  flex: 1;
  min-width: 60%;
  img{
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}

.login-form{
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 30px;
}

.form-section{
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: 100%;
  justify-content: center;
  align-items: center;
  h1{
    font-size: 32px;
    font-weight: 500;
  }
}
.input-field-wrapper{
  display: flex;
  gap: 20px;
  width: 100%;
  flex-direction: column;
}

.input-field{
  height: 30px;
  color: #000;
  &:focus,&:active{
    color: #000;
  }
  input{
    color: #000;
  }
}

.signin-button{
  border: none;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  /* width: max-content; */
  background-color: #007bff;
  border-radius: 5px;
  font-weight: 400;
  color: #ffffff;
  width: 100px;
  height: 30px;
  font-family: Poppins, sans-serif;
  &:hover{
    background-color: #1ca9e1;
  }
}

.brand-logo{
  display: flex;
  flex-direction: column;
  align-self: flex-end;
  /* flex: 1; */
  p{
    font-family: Poppins,sans-serif;
  }
}

.company-logo{
  align-self: flex-end;
  object-fit: cover;
  width: 180px;
}