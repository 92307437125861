.main-wrapper{
    display: flex;
    height: 100%;

}

.content-wrapper{
    display: flex;
    flex-direction: column;
    gap: 10px;
    flex: 1;
    padding: 10px;
    h1{
        color: #002A7A;
        font-family:Poppins, sans-serif;
        font-size: 26px;
        font-weight: 500;
        margin-left: 20px;
    }
}
.content{
    display: flex;
    flex: 1;
}